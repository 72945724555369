.dashboard_container {
    display: flex;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 25px;
    overflow: auto;
}

.dashboard {
    display: flex;
    overflow: wrap;
}

.traderTitle {
    padding-right: 15px;
}
